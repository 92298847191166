/**
 * NOTE: There's a big ol' stanky bug with styled-components where dynamically
 * updating styles based on prop changes rerenders the entire stylesheet,
 * including font-face declarations.
 *
 * Render this as separate CSS so it will be added separately from the
 * styled-components tag.
 *
 * https://github.com/styled-components/styled-components/issues/400
 */

/** Common Sofia Pro weights: 300 (light), 400 (regular), 700 (bold) */

@font-face {
  font-family: 'Sofia Pro';
  src: url('fonts/SofiaProLight-webfont.eot');
  src: url('fonts/SofiaProLight-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/SofiaProLight-webfont.woff2') format('woff2'),
    url('fonts/SofiaProLight-webfont.woff') format('woff'),
    url('fonts/SofiaProLight-webfont.ttf') format('truetype'),
    url('fonts/SofiaProLight-webfont.svg#sofiapro_light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('fonts/SofiaProLightIt-webfont.eot');
  src: url('fonts/SofiaProLightIt-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/SofiaProLightIt-webfont.woff2') format('woff2'),
    url('fonts/SofiaProLightIt-webfont.woff') format('woff'),
    url('fonts/SofiaProLightIt-webfont.ttf') format('truetype'),
    url('fonts/SofiaProLightIt-webfont.svg#sofiapro_light') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('fonts/SofiaProRegular-webfont.eot');
  src: url('fonts/SofiaProRegular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/SofiaProRegular-webfont.woff2') format('woff2'),
    url('fonts/SofiaProRegular-webfont.woff') format('woff'),
    url('fonts/SofiaProRegular-webfont.ttf') format('truetype'),
    url('fonts/SofiaProRegular-webfont.svg#sofiapro_regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('fonts/SofiaProRegularIt-webfont.eot');
  src: url('fonts/SofiaProRegularIt-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/SofiaProRegularIt-webfont.woff2') format('woff2'),
    url('fonts/SofiaProRegularIt-webfont.woff') format('woff'),
    url('fonts/SofiaProRegularIt-webfont.ttf') format('truetype'),
    url('fonts/SofiaProRegularIt-webfont.svg#sofiapro_regular') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('fonts/SofiaProBold-webfont.eot');
  src: url('fonts/SofiaProBold-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/SofiaProBold-webfont.woff2') format('woff2'),
    url('fonts/SofiaProBold-webfont.woff') format('woff'),
    url('fonts/SofiaProBold-webfont.ttf') format('truetype'),
    url('fonts/SofiaProBold-webfont.svg#sofiapro_bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('fonts/SofiaProBoldIt-webfont.eot');
  src: url('fonts/SofiaProBoldIt-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/SofiaProBoldIt-webfont.woff2') format('woff2'),
    url('fonts/SofiaProBoldIt-webfont.woff') format('woff'),
    url('fonts/SofiaProBoldIt-webfont.ttf') format('truetype'),
    url('fonts/SofiaProBoldIt-webfont.svg#sofiapro_bold') format('svg');
  font-weight: 700;
  font-style: italic;
}
